





















import { Component, Vue } from 'vue-property-decorator'
import { helpProblemList } from '../../../api'

@Component({
  name: ''
})
export default class extends Vue {
  list = []
  created() {
    const id: string | (string | null)[] = this.$route.query.id
    helpProblemList(id).then(res => {
      this.list = res.data.list
    })
  }
  handleItem(item: {
    id: string
    title: string
    type_id: number
    href: string
  }) {
    if (item.type_id == 1) {
      window.location.href = item.href
      return
    }

    if (item.type_id == 2) {
      this.$router.push(`/help-detail?id=${item.id}`)
    }
  }
}
